import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import ContactSection from "../../components/ContactSection"
import PageIntro2 from "../../components/PageIntro2"
import ServicesDisplay from "../../components/ServicesPage/ServicesDisplay"

const ServicesPage = ({ data }) => {
  return (
    // <motion.div
    //   initial='initial'
    //   animate='animate'
    //   exit="exit"
    //   className=""
    //   >
    <Layout navBarType="white">
      <Seo title="Services" />
      <main>
        <PageIntro2
          title="What we do"
          headline="Bring your vision to Shopify."
          text="Capture your customer's attention with a tailored Shopify solution."
        />
        <ServicesDisplay data={data} />
      </main>
      <ContactSection />
    </Layout>
    // </motion.div>
  )
}

export default ServicesPage

export const query = graphql`
  query ServicesPageQuery {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "service-page" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          name
        }
      }
    }
  }
`
