import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Service({
  heading,
  heading2,
  category,
  text,
  images,
  id,
  cta,
  imageRight,
  keywords,
}) {
  let featuredImage

  images.forEach(item => {
    if (item.node.name === id) {
      featuredImage = item.node.childImageSharp.gatsbyImageData
    }
  })

  return (
    // <Link to="/contact" className="hover:cursor-pointer">
    <div className="bg-white">
      <div
        className="flex flex-wrap items-center justify-between container mx-auto"
        style={{
          flexDirection: imageRight ? "row-reverse" : "row",
        }}
      >
        <div className="flex flex-col w-full sm:pt-4 lg:w-1/2 md:py-12 pt-12 lg:p-16 xl:p-24">
          <h2 className="text-xs mb-3 uppercase tracking-wider font-bold text-neutral-500">
            {category}
          </h2>
          <div className="text-4xl mb-4 font-bold font-display">
            <h3>{heading}</h3>
            <h3>{heading2}</h3>
          </div>

          <p className="text-base text-neutral-700 mb-6">{text}</p>
          <div className="flex space-between">
            {/* <p className="text-lg text-cyan-600 mb-6 hover:underline mr-8">
                          Learn more<FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-4"/>
                      </p> */}
            <Link
              to="#contact"
              className="text-lg text-cyan-600  mb-6 hover:underline group"
            >
              {cta}
              <FontAwesomeIcon
                icon={faChevronRight}
                className="ml-2 h-4 group-hover:translate-x-2 transition-transform"
              />
            </Link>
          </div>
          <div className="flex flex-wrap text-neutral-600">
            {keywords.map((item, id) => (
              <div
                key={id}
                className="flex align-center w-1/2 text-xs sm:text-md md:text-base pb-2"
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-neutral-600 fa-xl"
                />
                <div className="ml-2">{item}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:w-1/2">
          <GatsbyImage image={featuredImage} alt={`Image for ${category}`} />
        </div>
      </div>
    </div>
    // </Link>
  )
}
