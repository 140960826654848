import SectionIntroVersion2 from "../SectionIntroVersion2"
import Service from "./Service"

import ALL_SERVICES from "../../data/all_services"

export default function ServicesDisplay({ data }) {
  const images = data.allFile.edges

  const { DEVELOPMENT_SERVICES, OPTIMIZATION_SERVICES } = ALL_SERVICES

  return (
    <>
      {DEVELOPMENT_SERVICES.map((service, idx) => (
        <Service {...service} images={images} key={service.category + idx} />
      ))}

      <SectionIntroVersion2
        title="Optimization"
        headline="Sell smarter."
        headline2="Sell more."
        text="Engage your customers the right way with speed-optimized, data-driven, personalized experiences."
      />

      {OPTIMIZATION_SERVICES.map((service, idx) => (
        <Service {...service} images={images} key={service.category + idx} />
      ))}
    </>
  )
}
