const ALL_SERVICES = {
  DEVELOPMENT_SERVICES: [
    {
      category: "New store design and development",
      heading: "You dream it.",
      heading2: "I build it.",
      id: "1",
      text: "Join the over 1.7 million businesses that sell on Shopify. Smoothly, quickly, and easily.",
      extraText: "",
      keywords: [
        "Shopify Partners",
        "New Shopify store",
        "Responsive design",
        "Bespoke design",
        "Premium themes",
        "Built-in CMS",
        "App integration",
        "E-commerce",
        "Currency Selector",
        "Language Selectors",
      ],
      link: "/services",
      cta: "Start selling",
      imageRight: true,
    },
    {
      category: "Migration",
      heading: "Switching to Shopify?",
      heading2: "I got you.",
      id: "24",
      text: "Moving to Shopify has never been easier—no matter what platform you are currently using.",
      extraText: "",
      keywords: [
        "WooCommerce",
        "Magento",
        "Wix",
        "Squarespace",
        "Amazon",
        "BigCommerce",
        "BigCartel",
        "WordPress",
      ],
      link: "/services",
      cta: "Switch to Shopify",
      imageRight: false,
    },
    {
      category: "Theme Customization",
      heading: "See your unique vision come to life.",
      id: "34",
      text: "Stand out and drive sales with bespoke, personalized features that your customers will love.",
      extraText: "",
      keywords: [
        "Redesigns",
        "Bug fixes",
        "Tailored digital experience",
        "Theme customization",
        "Custom features",
        "Custom CMS",
        "Custom Shopify themes",
        "Dynamic free-shipping bar",
        "Shopify Partners",
      ],
      link: "/services",
      cta: "Improve your website",
      imageRight: true,
    },
    {
      category: "App Integration",
      // heading: "Hello, automation.",
      heading2: "Hello, free time.",
      id: "43",
      text: "Give yourself more time to focus on your customers with the 6,000+ apps and solutions Shopify has to offer.",
      extraText: "",
      keywords: [
        "Facebook channel",
        "Google channel",
        "Point of Sale",
        "Oberlo",
        "Pinterest",
        "Klaviyo",
        "Mailchimp",
        "Order Printer",
        "Judge.me",
        "Loox",
        "Instafeed",
        "Free Shipping Bar",
        "Matrixify",
      ],
      link: "/services",
      cta: "Integrate your favorite apps",
      imageRight: false,
    },
  ],
  OPTIMIZATION_SERVICES: [
    {
      category: "Conversion-Rate Optimization",
      heading: "Drive more sales through data.",
      id: "55",
      text: "Get to know who your customers are, how they behave, and what drives them, so you can sell better, smarter, more.",
      extraText: "",
      keywords: [
        "Google Analytics",
        "Market research",
        "Facebook Pixels",
        "Optimize by Google",
        "HotJar",
        "VWO",
        "Subscriptions",
        "Bulk selling",
      ],
      link: "/services",
      cta: "Optimize your store",
      imageRight: true,
    },
    {
      category: "Speed optimization",
      heading: "Your store just got faster.",
      id: "6",
      text: "Retain more visitors and reduce your website’s bounce rate by delivering faster page loading times.",
      extraText: "",
      keywords: [
        "Pagespeed Insights",
        "Faster loading time",
        "Lower visitor bounce rate",
        "Image optimization",
        "Lighthouse performance score",
      ],
      link: "/services",
      imageRight: false,
      cta: "Streamline your website",
    },
    {
      category: "Search Engine Optimization",
      heading: "Grow your organic traffic.",
      id: "7",
      text: "Increase your traffic organically and rank higher on search engines by shaping your website’s content to your potential customer.",
      extraText: "",
      keywords: [
        "Google Keywords",
        "SEO-friendly meta data",
        "SEO keywords",
        "Content relevance",
        "Backlinks",
        "Link popularity",
      ],
      link: "/services",
      cta: "Optimize your store",
      imageRight: true,
    },
  ],
}

export default ALL_SERVICES
